<template>
  <v-card flat height="100%" color="grey lighten-5">
    <v-card color="grey lighten-5" flat :to="'/products/category/' + product.slug">
      <v-img :src="product.images[0]" height="170px"></v-img>
      <v-card-title primary-title>
        <div class="text-truncate subtitle-1 grey--text text--darken-2">{{ product.name }}</div>
      </v-card-title>
      <v-card-text>
        <div class="caption grey--text text--darken-4">
          Price:
          <span class="caption grey--text text--darken-2">
            {{
            product.price | currency({
            fractionCount: 0
            })
            }}
          </span>
        </div>
      </v-card-text>
    </v-card>

    <v-card-actions>
      <v-btn
        v-if="!addCartButton"
        @click="addToCart(product)"
        outlined
        depressed
        large
        block
        color="teal teal--text"
      >
        <v-icon class="mr-2" color="teal">add_shopping_cart</v-icon>
        <span>Add</span>
      </v-btn>
      <v-btn dark large v-else to="/cart" block depressed color="teal">
        <v-icon class="mr-2" color="white">shopping_cart</v-icon>
        <span>Added</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["product"],
  computed: {
    ...mapGetters(["cartProducts"]),
    addCartButton(data) {
      let slug = data.product.slug;
      return this.checkProductInCart(slug);
    }
  },
  methods: {
    ...mapActions(["getSelectedProduct"]),

    addToCart(product) {
      //console.log(product)
      this.getSelectedProduct(product);
    },

    checkProductInCart(slug) {
      return this.cartProducts.some(product => product.slug == slug);
    }
  }
};
</script>

<style></style>
