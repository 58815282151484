<template>
  <div class="about" v-if="category">
    <v-container>
      <v-row class="gray--text fill-height">
        <v-col cols="12" md="8">
          <v-card-title
            :class="$vuetify.breakpoint.smAndDown ? 'title' : 'display-1'"
            class="font-weight-bold"
          >{{ category.name }}</v-card-title>
          <v-card-text v-text="category.description"></v-card-text>
        </v-col>
        <v-col cols="12" md="4">
          <v-img position="right" height="200" :src="category.image"></v-img>
        </v-col>
        <v-col cols="12">
          <v-toolbar flat inset>
            <v-text-field
              append-icon="search"
              hide-details
              outlined
              v-model="search"
              label="Search"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <v-row justify="space-between">
            <v-col cols="12" md="12" v-if="loading">
              <v-card flat class="text-center fill-height pt-5" height="299">
                <v-progress-circular color="grey" size="100" :indeterminate="loading"></v-progress-circular>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" v-for="product in filteredProducts" :key="product.name">
              <ProductCardWidget :product="product" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <!-- Toggle Button -->
          <v-btn
            v-if="$vuetify.breakpoint.xsOnly"
            fixed
            small
            @click.stop="menu = true"
            right
            class="mt-12"
            fab
            bottom
          >
            <v-icon color="primary">mdi-filter</v-icon>
          </v-btn>
          <!-- Popup Menu -->

          <v-dialog
            v-if="$vuetify.breakpoint.xsOnly"
            v-model="menu"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card tile flat>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="menu = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Filters</v-toolbar-title>
              </v-toolbar>

              <v-expansion-panels tile accordion>
                <v-expansion-panel cols="12" v-for="item in category.features" :key="item.id">
                  <v-expansion-panel-header>{{ item.name}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-checkbox
                      v-for="(option, index) in item.options"
                      :key="index"
                      v-model="filter"
                      :label="option"
                      :value="option"
                    ></v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
            <!-- Popup Menu -->
          </v-dialog>

          <v-row v-if="$vuetify.breakpoint.smAndUp">
            <v-col cols="12">
              <v-expansion-panels tile flat accordion>
                <v-toolbar>Filters</v-toolbar>
                <v-expansion-panel cols="12" v-for="item in category.features" :key="item.id">
                  <v-expansion-panel-header>{{ item.name}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-checkbox
                      v-for="(option, index) in item.options"
                      :key="index"
                      v-model="filter"
                      :label="option"
                      :value="option"
                    ></v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>
    <!-- Snackbar Notification Start -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- Snackbar Notification End -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ProductCardWidget from "./components/ProductCardWidget.vue";
export default {
  components: {
    ProductCardWidget,
  },
  data() {
    return {
      menu: false,
      search: "",
      filter: [],
      loading: false, //Loading
      //Snackbar
      snackbar: false,
      snackbarMessage: null,
      snackbarColor: "",
    };
  },
  computed: {
    ...mapGetters([
      "allProducts",
      "allProductCategories",
      "getProductByCategory",
    ]),
    id() {
      return this.$route.params.id;
    },
    category() {
      const category = this.allProductCategories.filter((category) => {
        return category.id === this.id;
      });
      return category[0];
    },
    products() {
      this.resetFilter();
      return this.allProducts.filter((product) => {
        return product.categoryId === this.id && product.active === true;
      });
    },

    filteredProducts() {
      return this.products.filter((product) => {
        // Do a case-insensitive search for "this.search" in a string
        let search = new RegExp(this.search, "i");
        // let tags = this.filter.valueOf();
        // let filter = new RegExp(tags, "i");
        // Return true or false each item in items array
        return product.name.match(search) ||
          product.description.match(search) ||
          product.sku.match(search) ||
          this.filter.length
          ? this.filter.every((filter) => product.tags.indexOf(filter) !== -1)
          : false;
        // this.filter.length === 0
        // ? product.tags.reduce((acc, cur) => {
        //     console.log("A" + acc + " " + "C" + cur);
        //     return !(!acc || !this.filter.includes(cur));
        //   }, true)
        // : true;
        // this.filterTags(product.tags)
      });
    },
  },
  methods: {
    ...mapActions(["bind"]),
    filterTags(data) {
      const tags = data;
      // console.log(tags);
      const result = tags.reduce((acc, cur) => {
        // console.log("Condition" + acc + " Value is" + cur);
        return !(!acc || !this.filter.includes(cur));
      }, true);
      // console.log("Return" + result);
    },

    fetchProducts() {
      if (this.allProducts == 0) {
        this.loading = true;
        // Bind Products
        this.bind({
          collection: "products",
          data: "products",
        })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.snackbarMessage = "Please check your internet connection";
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
    fetchCategory() {
      if (this.allProductCategories.length == 0) {
        this.bind({
          collection: "productCategories",
          data: "productCategories",
        });
      }
    },
    resetFilter() {
      this.filter = [];
    },
  },
  created() {
    this.fetchProducts();
    this.fetchCategory();
  },
};
</script>
